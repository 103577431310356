var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('div',[(
        (_vm.discountPrice == null && _vm.price > 0) ||
          (_vm.discountPrice == _vm.price && _vm.discountPrice != 0 && _vm.price != 0)
      )?_c('span',{staticStyle:{"color":"#ed5656"}},[_vm._v("¥ "+_vm._s(_vm.price))]):_vm._e()]),(
      (_vm.discountPrice == 0 && _vm.price == 0) ||
        (_vm.discountPrice == null && _vm.price == 0)
    )?_c('div',[_c('span',{staticStyle:{"color":"#ed5656"}},[_vm._v("免费")])]):_vm._e(),(_vm.discountPrice > 0 && _vm.discountPrice !== _vm.price)?_c('div',[_c('span',{staticStyle:{"color":"#ed5656"}},[_vm._v("¥"+_vm._s(_vm.discountPrice))])]):_vm._e(),(_vm.discountPrice == 0 && _vm.price > 0 && _vm.discountPrice !== _vm.price)?_c('div',[_c('span',{staticStyle:{"color":"#ed5656"}},[_vm._v("免费")])]):_vm._e(),(_vm.discountPrice >= 0 && _vm.price > 0 && _vm.discountPrice !== _vm.price)?_c('div',{staticClass:"price-origin"},[_c('span',[_vm._v("¥"+_vm._s(_vm.price))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }