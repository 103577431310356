var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.positionList.length > 2)?_c('div',[_c('div',{staticClass:"home-title"},[_vm._v(" "+_vm._s(_vm.positionMap[_vm.positionId.topListId])+" "),_c('span',{staticClass:"home-more",on:{"click":function($event){return _vm.$router.push({
          name: _vm.nextMoreName,
          params: { tab: _vm.positionId.topListId }
        })}}},[_vm._v(" MORE "),_c('img',{attrs:{"src":require('@/assets/images/路径@2x.png')}})])]),_c('div',{staticClass:"home-top"},_vm._l((_vm.topList),function(item,index){return _c('div',{key:index,staticClass:"top-item"},[_c('div',{staticClass:"left",style:({
          color:
            index == 0
              ? '#E07471'
              : index == 1
              ? '#F39965'
              : index == 2
              ? '#F2BB73'
              : '#e2e5eb'
        })},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"right"},[_c('div',{directives:[{name:"popover",rawName:"v-popover:popover",arg:"popover"}],staticClass:"title",on:{"click":function($event){return _vm.openVideo(item)}}},[_vm._v(" "+_vm._s(item.liveTitle)+" ")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"uv"},[_vm._m(0,true),_c('div',[_vm._v(_vm._s(item.livePlayPv))])]),_c('div',{staticClass:"price"},[_c('Price',{attrs:{"price":item.price,"discountPrice":item.discountPrice}})],1)])])])}),0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"2px"}},[_c('img',{attrs:{"src":require("@/assets/images/火@2x.png"),"alt":""}})])
}]

export { render, staticRenderFns }